.posts {
    height: calc(100vh - 5rem);
    background-color: #E6DDC4;
    padding: 1rem;
    border-radius: 10px;
}

.posts__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    max-height: calc(100vh - 6rem);
}